<template>
  <div data-test="content-contact-role">
    {{ role }}
  </div>
</template>

<script>
import { roles } from '@/constants';

export default {
  name: 'ContentContact',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      roles,
      staffRoles: {
        TUTOR: 'Наставник',
        HR: 'HR',
        MENTOR: 'Руководитель',
        STAFF: 'Сотрудник',
      },
    };
  },
  computed: {
    meta() {
      return JSON.parse(this.data.meta);
    },
    role() {
      return this.staffRoles[this.meta.type];
    },
  },
};
</script>

<style>
  /* stylelint-ignore no-empty-source */
</style>
